import React, { useState } from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Container, Row, Col } from 'react-bootstrap';
import moment from "moment/dist/moment";
import BlogSection from "@Components/BlogSection/BlogSection"
import HomeGetStart from "@Components/Home/HomeGetStart/HomeGetStart";
import BreadcrumbPage from "@Components/Home/BreadcrumbPage/BreadcrumbPage";
import Newsletter from "@Components/Newsletter/Newsletter";
import ScrollAnimation from "react-animate-on-scroll";

import { inViewOptions } from '../../Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import logoBlack from "../../images/logo-t.svg";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const BlogDetails = (props) => {
  const GET_BLOG_DETAILS = gql`
  query GetBLog($URL: String!){	
      blogs(where:{URL: $URL}) {
        Category
      Banner_Video
      Date
      Layout
      Meta_Description
      Meta_Title
      Modules {
        ... on ComponentModuleBlogContent{
            id
            Add_Blocks {
              Content
              Image {
                alternativeText
                url
              }
            }
        }
        }
      Banner_Image {
        alternativeText
        url
      }
      Publish
      Title
      URL

      
  }
}
`;

  const { loading, error, data } = useQuery(GET_BLOG_DETAILS, {
    variables: { URL: props.slug }
  });
  let shareurl = '';

  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : '';
  }

  // console.log(data)

  // if (loading) return (
  //   <section className={"loader-wrapper"}>
  //     <div id="loader-wrapper">
  //       <div id="loader" class="new-loader">
  //         <div className="new-loader-anime"></div>
  //         <img className="logo-white" src={logoBlack} className="loader-logo" alt="logo" />
  //       </div>
  //       <div class="loader-section section-left"></div>
  //       <div class="loader-section section-right"></div>
  //     </div>
  //   </section>
  // );

  if (loading) return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
        <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
          </div>
      </Container>
    </div>
  );

  <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          <div className="new-loader-anime"></div>
          <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
        </div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
      </div>
    </section>
  
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <Layout popularSearch={"Common"} isBlack={true} className="blog-page" header={true}>
                {data?.blogs.map((Page, i) => {
                  return (
                    <>
                      <SEO title={Page ? Page.Title : ''} description={Page ? 'Read about '+Page.Title+' here, subscribe now and stay updated for all latest property news.' : ''} />
                      <Helmet
                        bodyAttributes={{
                          class: `templates-blog-details-template`
                        }}
                      />
                      <div className="news-bg-bk">
                        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                          <Container>
                            <motion.div variants={titleVariants}>
                              <BlogSection
                                page={Page}
                              />
                              {/* {JSON.stringify(Page)} */}
                            </motion.div>
                          </Container>
                        </ScrollAnimation>
                      </div>

                      <motion.div variants={contentVariants}>
                        <Newsletter />
                        <HomeGetStart nobanner={true} />
                        <BreadcrumbPage Page={Page.Title} class="static" alias={'news'} type="details-page" />
                      </motion.div>
                    </>
                  )
                })}
              </Layout>
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  );
}



export default BlogDetails