import * as React from "react"
import { Container, Row, Col, Form } from "react-bootstrap"

// import NewsletterForm from "@Components/forms/newsletter-form";
import ReactMarkdown from "react-markdown"
import ScrollAnimation from 'react-animate-on-scroll';

import SocialShare from "@Components/Share/SocialShare";
import moment from "moment/dist/moment";
import { BLOGS_PAGE_URL, ADVICE_PAGE_URL } from "@Components/common/site/constants";
import {
    VALUATION_PAGE_URL
  } from "@Components/common/site/constants";
import "./BlogSection.scss"
import { Link } from "@StarberryUtils";
const BlogSection = (props) => {
    // console.log(props.page)
    return (
        props.isPage ?
            <section className="blog-section page">
                <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {/* {JSON.stringify(props.page)} */}
                                <div className="blog-content">
                                    <h1>{props?.Title}</h1>
                                    <ReactMarkdown source={props?.page?.Content} allowDangerousHtml />
                                </div>
                            </Col>
                            
                        </Row>

                    </Container>
                </ScrollAnimation>

            </section>

            : <section className="blog-section">
            <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                    <Row>
                        <Col xs={12}>
                            {/* {JSON.stringify(props.page)} */}
                            <div className="blog-content">
                                <h1>{props.page?.Title}</h1>
                                <div className="about-content">
                                    <div className="icon-social icons">
                                            <i className="icon-calendar">
                                            </i>
                                        <span className="sm-text">
                                            {moment(props.page?.Date).format('DD MMMM YYYY')}
                                        </span>
                                    </div>
                                    <SocialShare Title="Share this post" />
                                    <div className="icon-social d-lg-flex">
                                            <i className="icon-candidate">
                                            </i>
                                        <span className="sm-text">
                                            {props.page?.Category}
                                        </span>
                                    </div>
                                    
        <div className="clear"/>
                                </div>
                                {props.page?.Modules && props.page?.Modules?.length > 0 ? props.page?.Modules.map((Module, i) => {
                                    return (
                                        <div key={i}>
                                            {Module?.Add_Blocks.map((item, j) => {
                                                return (
                                                    <div key={j}>
                                                        {j === 2 ? <div className="ct-blk">
                                                            <h4>Do you need a property valuation?</h4>
                                                            <p>Please get in touch with one of our property experts.</p>
                                                            <Link to={VALUATION_PAGE_URL.alias} className="btn btn-primary">Get in touch</Link>
                                                        </div> : null}
                                                        {item.Content &&
                                                            <ReactMarkdown source={item?.Content} allowDangerousHtml />
                                                        }

                                                        {item.Image?.length === 1 &&
                                                            <div className="blog-img">
                                                                <picture>
                                                                    <source media="(min-width:992px)" srcSet={item.Image[0]?.url} alt={item.Image[0]?.Add_BlocksalternativeText} />
                                                                    <source media="(min-width:768px)" srcSet={item.Image[0]?.url} alt={item.Image[0]?.alternativeText} />
                                                                    <img src={item.Image[0]?.url} alt={item.Image[0]?.alternativeText} />
                                                                </picture>
                                                            </div>}
                                                        {item.Image?.length === 2 &&
                                                            <div className="img-group">
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className="img-zoom article-img">
                                                                            <picture>
                                                                                <source media="(min-width:992px)" srcSet={item.Image[0]?.url} alt={item.Image[0]?.Add_BlocksalternativeText} />
                                                                                <source media="(min-width:768px)" srcSet={item.Image[0]?.url} alt={item.Image[0]?.alternativeText} />
                                                                                <img src={item.Image[0]?.url} alt={item.Image[0]?.alternativeText} />
                                                                            </picture>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="img-zoom article-img">
                                                                            <picture>
                                                                                <source media="(min-width:992px)" srcSet={item.Image[1]?.url} alt={item.Image[1]?.Add_BlocksalternativeText} />
                                                                                <source media="(min-width:768px)" srcSet={item.Image[1]?.url} alt={item.Image[1]?.alternativeText} />
                                                                                <img src={item.Image[1]?.url} alt={item.Image[1]?.alternativeText} />
                                                                            </picture>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            })}

                                        </div>

                                    )
                                }) : ''}
                            </div>
                        </Col>
                        {/* <Col lg={12}>
                            <div className="subscribe">

                                <span className="sm-text">
                                    <Link href="#" className="d-none d-lg-flex">
                                        <i className="icon-contact"></i>
                                    </Link>
                        Stay up to date
                          </span>
                                <NewsletterForm />

                            </div>
                        </Col> */}
                    </Row>

            </ScrollAnimation>
            <SocialShare iconshare={true} Title="Share this post" />

        </section>)
}
export default BlogSection