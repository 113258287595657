import React, { useState } from 'react';
import { Modal, Button, Form, Container, FormControl, Row, InputGroup, Col, Dropdown} from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import NewsletterForm from "@Components/forms/newsletter-form-module";
import "./Newsletter.scss"
const Newsletter =(props) => {
  // Declare a new state variable, which we'll call "count"
  const dataglobal = useStaticQuery(graphql`
  query{
  glstrapi {
    globalConfig(publicationState: LIVE) {
        Newsletter_Module_Title
        Newsletter_Module_Content
    }
  }
  }
  `);    
  return (
    <div className="newsletter">
        <Container>
            <Row>
                <Col lg="6">
                    <div className="content-bk">
                        <h4>{dataglobal?.glstrapi?.globalConfig?.Newsletter_Module_Title}</h4>
                        <ReactMarkdown source={dataglobal?.glstrapi?.globalConfig?.Newsletter_Module_Content} allowDangerousHtml />
                    </div>
                </Col>
                <Col lg="6">
                    <div className="newsletter-form-blk">
                    <NewsletterForm />
                    </div>
                </Col>
            </Row>
        </Container>

    </div>
  );
}

export default Newsletter;